import React from 'react';

export default function Root() {
    return (
        <div>
            <h1>Welcome to My React Site!</h1>
            <p>This is a placeholder homepage.</p>
        </div>
    );
};
